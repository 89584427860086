import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./src/styles/global.css";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
	`This application has been updated. ` +
	  `Reload to display the latest version?`
  )

  if (answer === true) {
	window.location.reload()
  }
}